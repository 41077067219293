import React from 'react';
import './css/Home.css';

import { Element } from 'react-scroll';

const Home = () => {
  return (
    <div className="home">
      <Element name="home">
      <div id="home">
        <div className='hometesto'>
        <h1>Hi, I'm Nicola Quattrone</h1>
        <h2>Jack of all trades, master of none</h2>
        <p>I'm an engineer passionate about innovation and technology. I love creating new software and physical products. These are my favorite tools:</p>
        <div className='toolslist'>
        <div className='tools'><p>Python</p></div>
        <div className='tools'><p>Pytorch</p></div>
        <div className='tools'><p>Streamlit </p></div> 
        <div className='tools'><p>Flutter</p></div>
        <div className='tools'><p>React</p></div>
        <div className='tools'><p>HMTL</p></div>
        <div className='tools'><p>CSS</p></div>
        </div>
        </div>
        <div className='homeimage'>
        </div>
       
      </div>
      
      </Element>
      
   
    </div>
  )
};

export default Home;
