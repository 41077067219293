import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './css/Navbar.css';

const NavbarProjects = () => {
  
  return (
    <nav className="navbarprojects">
      <ul>
        <li>
        <RouterLink to="/">
         Home
        </RouterLink>
        </li>
        
        <li>
          <RouterLink to="/projects">
            Projects
          </RouterLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarProjects;
