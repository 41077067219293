import React from 'react';
import './css/Projects.css';


const About = () => {

  return (
    <div className='projects'>
      <h1>Coming soon</h1>
      <div className='soon' ></div>
    </div>
    
  );
};

export default About;
       

