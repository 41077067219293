import React from 'react';
import './css/BottomBar.css';

function BottomBar() {
  return (
    
     <div className='bottom-bar'>
     <div className="footer">
      <footer>
       {/* <div class="footer_buttons">
          
          <a href="/" aria-label="link join us"></a>
          <a href="/" aria-label="link press"></a>
          
          <a href="/" aria-label="link privacy policy">privacy policy</a>
          <a href="/" aria-label="link terms of use">terms of use</a>
        </div>

        <div class="footer_line">
          <hr width="90%" size="1" color="#fffff" />
        </div>
        */}
        <div className="footer_content">
          <p>&copy; 2024 Nicola Quattrone</p>
          
          <div className="media-icons">
            
            <a href="https://www.linkedin.com/in/nicola-quattrone/" aria-label="link linkedin">
              <svg
                className="linkedin footer_icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                />
              </svg>
            </a>

            <a href="https://github.com/Nicola97" aria-label="link github">
              
              <svg
              className="github footer_icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              >
              
              <path
              d="M12 0.297c-6.63 0-12 5.373-12 12 0 5.302 3.438 9.8 8.205 11.385 0.6 0.111 0.82-0.261 0.82-0.577 0-0.285-0.01-1.04-0.015-2.04-3.338 0.726-4.042-1.612-4.042-1.612-0.546-1.387-1.333-1.758-1.333-1.758-1.091-0.745 0.083-0.729 0.083-0.729 1.205 0.084 1.839 1.24 1.839 1.24 1.07 1.835 2.807 1.305 3.492 0.998 0.108-0.775 0.418-1.305 0.76-1.605-2.665-0.304-5.466-1.335-5.466-5.93 0-1.31 0.467-2.381 1.235-3.221-0.123-0.303-0.535-1.527 0.117-3.176 0 0 1.008-0.322 3.301 1.23 0.957-0.266 1.983-0.399 3.003-0.403 1.019 0.004 2.045 0.137 3.003 0.403 2.291-1.552 3.297-1.23 3.297-1.23 0.653 1.649 0.242 2.873 0.119 3.176 0.77 0.84 1.231 1.911 1.231 3.221 0 4.609-2.804 5.623-5.475 5.921 0.43 0.371 0.814 1.103 0.814 2.222 0 1.604-0.015 2.896-0.015 3.286 0 0.319 0.217 0.694 0.824 0.576 4.765-1.584 8.2-6.082 8.2-11.384 0-6.627-5.373-12-12-12z"
              />
              </svg>
            </a>

            {/*
            <a href="" aria-label="link tiktok">
              <svg
                class="tiktok footer_icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
        
                <path
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                />
              </svg>
            </a>
            */}
            <a href="https://www.instagram.com/nicola4ne/" aria-label="link instagram">
              <svg
                className="instagram footer_icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
            </a>
          </div>
        </div>
      </footer>
      </div>
      </div>
      
    
  );
}

export default BottomBar;
