import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './css/Navbar.css';

function Navbar()  {
  const [scrolled, setScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 518) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let navbarClasses = ['navbar'];
  if (scrolled) {
    navbarClasses.push('scrolled');
  }
  
  return (
    <nav  className={navbarClasses.join(' ')}>
      <ul>
        <li>
        <RouterLink to="/">
         Home
        </RouterLink>
        </li>
        
        <li>
          <RouterLink to="/projects">
            Projects
          </RouterLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
