import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import Navbar from './components/Navbar';
import BottomBar from './components/BottomBar';
import Contact from './components/Contact';
import NavbarProjects from './components/NavbarProjects';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavbarSwitcher />
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeWithContact />}/>
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </div>
      <BottomBar />
    </Router>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [pathname]);

  return null; // Renders nothing, doesn't affect the UI
}

function NavbarSwitcher() {
  const { pathname } = useLocation();

  // Render NavbarProjects only on /projects route
  if (pathname === '/projects') {
    return <NavbarProjects />;
  }

  // Render default Navbar for other routes
  return <Navbar />;
}

function HomeWithContact() {
  const { pathname } = useLocation();

  // Mostra il componente Contact solo sulla pagina Home ('/')
  if (pathname === '/') {
    return (
      <>
        <Home />
        <Contact />
      </>
    );
  }

  return <Home />;
}

export default App;
