import React from 'react';
import { InlineWidget } from 'react-calendly';


const Contact= () => {
  const calendlyUrl = 'https://calendly.com/nicolaquattrone97/30min';

  return (
    <div id='contact'>
        {/*<div className='contact'></div>*/}
        <div className='testocontact'>
            <h2>Contact me on LinkedIn or book a call for a chat or advice.</h2>
            <p>But remember: </p>
            <p>"Be careful whose advice you buy, but be patient with those who supply it. Advice is a form of nostalgia. Dispensing it is a way of fishing the past from the disposal, wiping it off, painting over the ugly parts and recycling it for more than it’s worth."</p>        
        </div>
        
        <InlineWidget className="calendly-inline-widget"url={calendlyUrl} />

    </div>
  );
};

export default Contact;
